<template>
    <div style="min-height: calc(100vh - 158px);" class="card">
        <div class="p-2">
            <TitleButton
                :showBtn="false"
                :showAddNew="false"
                title="Export Datasheet"
            />

            <div class="row match-height mt-1">
                <div class="col-12">
                    <v-select
                        v-model="dataType"
                        :options="dataTypes"
                        label="name"
                        :reduce="name => name.value"
                        placeholder="Select Datasheet"
                        @option:selected="onChangeSheet"
                    />
                </div>
                <div class="col-12 mt-2">
                    <DateQuerySetter
                        @onClickGo="exportDataSheet"
                        btn-title="Export"
                        :isHideDate="selectedSheet.is_export_all"
                    >
                        <div v-if="selectedSheet.value === 'contact_profiles'" class="col-12 col-sm-6 col-md-4 col-lg-3">
                            <label for="colFormLabel" class="col-form-label">Roles</label>
                            <v-select
                                placeholder="Select Roles"
                                v-model="roleId"
                                :options="companyRules"
                                label="name"
                                :reduce="name => name.id"
                            />
                        </div>
                    </DateQuerySetter>
                </div>
            </div>
        </div>
        <Loader v-if="loading"/>
    </div>
</template>

<script setup>
import TitleButton from '@/components/atom/TitleButton'
import DateQuerySetter from '@/components/atom/DateQuerySetter'
import Loader from '@/components/atom/LoaderComponent'
import {inject, ref, computed, onMounted} from 'vue'
import {useRoute} from "vue-router";
import handleRole from "@/services/modules/role";

const loading = ref(false);
const dataType = ref(null);
const showError = inject('showError');
const routes = useRoute();
const token = localStorage.getItem('token');
const selectedSheet = ref({});
const { fetchCompanyDefaultRoles } = handleRole();
const companyRules = ref([]);
const roleId = ref(null)

const dataTypes = [
    {
        name: "Chart of Accounts",
        value: "account_heads"
    },
    {
        name: "General Ledger",
        value: "ledger_generals"
    },
    {
        name: "Locations",
        value: "areas",
        is_export_all: true
    },
    {
        name: "BDO",
        value: "sale_representatives",
        is_export_all: true
    },
    {
        name: "Contact Profile",
        value: "contact_profiles",
        is_export_all: true
    },
    {
        name: "Bank Branches",
        value: "dealers",
        is_export_all: true
    }
];

const start = computed( () => {
    return routes.query.start
})
const end = computed( () => {
    return routes.query.end
})
const companyId = computed( () => {
    return routes.params.companyId
})

const onChangeSheet = (data) => {
    selectedSheet.value = data;
}

const getCompanyRoles = async () => {
    const res = await fetchCompanyDefaultRoles(`?company_id=${companyId.value}`);
    if (res.status) {
        companyRules.value = res.data
    }
}

const exportDataSheet = () => {
    if(dataType.value === null){
        showError('Please select datasheet to export');
        return
    }
    let url = `${process.env.VUE_APP_BASE_URL}/export/datasheet/${dataType.value}?company_id=${companyId.value}`
              + `&start_date=${start.value}&end_date=${end.value}&_token=${token}`;

    if(selectedSheet.value.is_export_all) url += `&is_export_all=1`;
    
    if(selectedSheet.value.value === 'contact_profiles' && roleId.value) url += `&role_id=${roleId.value}`;

    let a = document.createElement('a');
    a.setAttribute('href', url);
    a.click();
}

onMounted(() => {
    getCompanyRoles();
})
</script>